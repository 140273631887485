import { MaterialIcons } from '@expo/vector-icons';
import { FieldArray, useFormikContext } from 'formik';
import { Flex, Icon, Text } from 'native-base';
import React, { useMemo } from 'react';
import { TouchableOpacity } from 'react-native';

import {
  AddForwardingPurchaseModal,
  BoxWrapper,
  CalculateCostDeliveryButton,
  DesktopTable,
  TextField,
  TextLink,
  Typography,
} from '../../../../components';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import { useIntl } from '../../../../hooks';
import ForwardingStructureMobileItem from './ForwardingStructureMobileItem';

const ForwardingStructure = () => {
  const { values, setFieldValue } = useFormikContext();

  const intl = useIntl();

  const purchasesQuantity = values.purchases.reduce(
    (accumulator, { quantity }) => accumulator + Number(quantity),
    0,
  );
  const purchasesTotalPrice = values.purchases.reduce(
    (accumulator, { price, quantity }) => accumulator + Number(price) * Number(quantity),
    +values.deliveryPrice ?? 0,
  );

  const handleAddPurchase = (purchase, addField) => {
    addField({
      product: purchase.product,
      parcel: {
        id: purchase.parcelId,
        trackingCode: purchase.parcelTrackingCode,
      },
      id: purchase.id,
      price: purchase.price,
      quantity: purchase.quantity,
      deliveryPrice: undefined,
    });
  };

  const forwardingParcelsIds = useMemo(
    () => values?.parcels.map((parcel) => parcel?.id) || [],
    [values?.parcels],
  );

  if (IS_DESKTOP_SCREEN) {
    return (
      <BoxWrapper>
        <Flex direction={'row'} justify={'space-between'} align={'center'}>
          <Typography mb={0} intlId='app.structure' variant='heading' />
          <Flex>
            <CalculateCostDeliveryButton values={values} setFieldValue={setFieldValue} />
            <FieldArray
              name={'purchases'}
              render={(arrayHelpers) => (
                <AddForwardingPurchaseModal
                  forwardingParcels={forwardingParcelsIds}
                  addedPurchases={values?.purchases}
                  isMultiSelect
                  onAddPurchase={(purchase) =>
                    handleAddPurchase(purchase, arrayHelpers.push)
                  }
                  buttonText={'app.fillIn'}
                />
              )}
            />
          </Flex>
        </Flex>

        <DesktopTable editable={false}>
          <DesktopTable.Head>
            <DesktopTable.Title intlId='app.purchase' />
            {values?.parcels?.length ? <DesktopTable.Title intlId='app.parcel' /> : null}
            <DesktopTable.Title intlId='app.costUSD' />
            <DesktopTable.Title intlId='app.priceDeliveryUSD' />
            <DesktopTable.Title intlId='app.quantity' />
            <DesktopTable.Title intlId='app.sum' />
          </DesktopTable.Head>
          <DesktopTable.Body>
            <FieldArray
              name={'purchases'}
              render={(arrayHelpers) => {
                return (
                  <>
                    {values?.purchases?.map((item, index) => {
                      const handleRemove = () => {
                        arrayHelpers.remove(index);
                      };

                      return (
                        <DesktopTable.Row key={item?.id}>
                          <DesktopTable.Cell width={'800px'} maxWidth={'800px'}>
                            <TextLink link={`/purchase/purchase/${item.id}`}>
                              {item.product.name}
                              <Text color='black'>{` (${item.id})`}</Text>
                            </TextLink>
                          </DesktopTable.Cell>
                          <DesktopTable.Cell>
                            <Text>{item?.parcel?.trackingCode}</Text>
                          </DesktopTable.Cell>
                          <DesktopTable.Cell>
                            <TextField
                              inputProps={{
                                textAlign: 'right',
                              }}
                              width={'90px'}
                              mb={0}
                              isDisabled
                              type={'number'}
                              name={`purchases[${index}].price`}
                              placeholderIntlId={'app.price'}
                            />
                          </DesktopTable.Cell>
                          <DesktopTable.Cell>
                            <TextField
                              inputProps={{
                                textAlign: 'right',
                              }}
                              width={'90px'}
                              mb={0}
                              isDisabled
                              type={'number'}
                              name={`purchases[${index}].deliveryPrice`}
                              placeholderIntlId={'app.delivery'}
                            />
                          </DesktopTable.Cell>
                          <DesktopTable.Cell>
                            <TextField
                              inputProps={{
                                textAlign: 'center',
                              }}
                              width={'48px'}
                              mb={0}
                              isDisabled
                              type={'number'}
                              numberFixedCount={0}
                              name={`purchases[${index}].quantity`}
                              placeholderIntlId={'app.quantity'}
                            />
                          </DesktopTable.Cell>
                          <DesktopTable.Cell>
                            <Flex
                              direction={'row'}
                              align={'center'}
                              justify={'space-between'}
                            >
                              <Text>
                                {(
                                  (+item?.price + (+item?.deliveryPrice || 0)) *
                                  item?.quantity
                                ).toFixed(2)}{' '}
                                $
                              </Text>
                              <TouchableOpacity onPress={handleRemove}>
                                <Icon
                                  size={'sm'}
                                  color={'gray.dark'}
                                  as={<MaterialIcons name={'delete'} />}
                                />
                              </TouchableOpacity>
                            </Flex>
                          </DesktopTable.Cell>
                        </DesktopTable.Row>
                      );
                    })}
                  </>
                );
              }}
            />
            <DesktopTable.Row>
              <DesktopTable.Cell>
                <></>
              </DesktopTable.Cell>
              {!!values?.parcels?.length && (
                <DesktopTable.Cell>
                  <></>
                </DesktopTable.Cell>
              )}
              <DesktopTable.Cell>
                <></>
              </DesktopTable.Cell>
              <DesktopTable.Cell>
                <></>
              </DesktopTable.Cell>
              <DesktopTable.Cell>
                <Flex justify={'flex-start'}>
                  <Text>
                    {`${purchasesQuantity} ${intl.formatMessage({ id: 'app.pcs' })}`}
                  </Text>
                </Flex>
              </DesktopTable.Cell>
              <DesktopTable.Cell>
                <Flex justify={'flex-start'}>
                  <Text>{purchasesTotalPrice} $</Text>
                </Flex>
              </DesktopTable.Cell>
            </DesktopTable.Row>
          </DesktopTable.Body>
        </DesktopTable>
      </BoxWrapper>
    );
  }

  return (
    <BoxWrapper>
      <Flex direction={'row'} justify={'space-between'} align={'center'}>
        <Typography margin={'24px 0px'} intlId='app.structure' variant='heading' />
        <FieldArray
          name={'purchases'}
          render={(arrayHelpers) => (
            <AddForwardingPurchaseModal
              forwardingParcels={forwardingParcelsIds}
              addedPurchases={values?.purchases}
              isMultiSelect
              onAddPurchase={(purchase) => handleAddPurchase(purchase, arrayHelpers.push)}
              buttonText={'app.fillIn'}
            />
          )}
        />
      </Flex>
      <Flex align={'center'} justify={'center'}>
        <CalculateCostDeliveryButton values={values} setFieldValue={setFieldValue} />
      </Flex>
      <FieldArray
        name={'purchases'}
        render={(arrayHelpers) => {
          return (
            <>
              {values?.purchases?.map((item, index) => {
                const handleRemove = () => {
                  arrayHelpers.remove(index);
                };

                return (
                  <ForwardingStructureMobileItem
                    key={item?.id}
                    item={item}
                    handleRemove={handleRemove}
                    index={index}
                  />
                );
              })}
            </>
          );
        }}
      />
      <Flex mt={'lg'} justify={'space-between'} align={'center'}>
        <Text>{intl.formatMessage({ id: 'app.all' })}</Text>
        <Flex align={'center'}>
          <Text>{`${purchasesQuantity} ${intl.formatMessage({ id: 'app.pcs' })}`}</Text>
          <Text ml={'lg'}>{purchasesTotalPrice} $</Text>
        </Flex>
      </Flex>
    </BoxWrapper>
  );
};

ForwardingStructure.propTypes = {};

export default ForwardingStructure;
