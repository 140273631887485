import { useState } from 'react';

import { useNotificationsContext } from '../context';
import useHistory from './useHistory';
import { usePurchaseParcelMutation, useUpdatePurchasesMutation } from './useMutations';

export const useSaveParcel = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  const [purchaseParcelMutation] = usePurchaseParcelMutation();

  const [updatePurchases] = useUpdatePurchasesMutation();

  const [isSaveParcelLoading, setIsSaveParcelLoading] = useState(false);

  const onSaveParcel = async (values) => {
    setIsSaveParcelLoading(true);

    const addParcelVariables = {
      forwardingIds: values?.forwardings?.filter((el) => el?.id).map((el) => +el.id),
      date: values?.date ? new Date(values.date) : undefined,
      trackingCode: values.trackingCode,
      sourceId: Number(values.source),
      statusId: Number(values.status),
    };

    if (values.id) {
      addParcelVariables.id = values.id;
    }

    const purchaseParcelResponse = await purchaseParcelMutation({
      variables: { input: addParcelVariables },
    });

    const purchaseParcel = purchaseParcelResponse?.data?.purchaseParcel;

    if (purchaseParcel) {
      const purchases = values.purchases.map((item) => ({
        id: !item?.isNew ? +item?.id : undefined,
        productId: !item?.isNew ? +item?.product?.id : +item?.id,
        price: +item.price,
        trackingParcelCode: purchaseParcel?.trackingCode,
        parcelStatus: +purchaseParcel?.status?.id,
        parcelId: +purchaseParcel?.id,
        quantity: +item.quantity,
        purchaseTime: !item?.isNew
          ? item?.purchaseTime
          : values?.date
          ? new Date(values.date)
          : undefined,
      }));

      if (purchases.length) {
        await updatePurchases({
          variables: {
            input: { purchases },
          },
          onCompleted: (response) => {
            if (response?.purchases) {
              showNotification({ message: 'parcel.saved' });
              setIsSaveParcelLoading(false);
              navigation.push('/purchase/parcels');
            }
          },
        });
      } else {
        setIsSaveParcelLoading(false);
        showNotification({ message: 'parcel.saved' });
        navigation.push('/purchase/parcels');
      }
    }
  };
  return [onSaveParcel, isSaveParcelLoading];
};
