import { Box, Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { useNotificationsContext, useSearchContext } from '../../context';
import { useFindPurchaseForwardingParcels } from '../../hooks';
import { formatDateTime } from '../../utils';
import {
  AddModal,
  Button,
  DesktopTable,
  ImageView,
  ModalButton,
  PurchaseCard,
  RequestHandler,
  Typography,
} from '../index';

export const AddForwardingPurchaseModal = ({
  onAddPurchase,
  buttonText = 'app.select',
  addedPurchases = [],
  isMultiSelect = false,
  forwardingParcels = [],
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const intl = useIntl();

  const { showNotification } = useNotificationsContext();

  const { searchValue, changeSearchValue } = useSearchContext();

  const { data: purchaseParcelsData, ...queryProps } = useFindPurchaseForwardingParcels(
    forwardingParcels,
    0,
  );

  const addedPurchasesIds = addedPurchases?.map((el) => el?.id);

  const onAddPurchaseHandler = (purchase) => {
    onAddPurchase(purchase);
    !isMultiSelect && setIsModalOpen(false);
  };

  const handlerButtonPress = () => {
    if (!forwardingParcels.length) {
      showNotification({
        message: 'app.chooseTrackingCode',
        type: 'error',
        title: 'snackbar.error',
      });
    } else {
      setIsModalOpen(true);
      isError && setIsError(false);
    }
  };

  const purchases = useMemo(
    () =>
      purchaseParcelsData
        ? [
            ...purchaseParcelsData?.purchaseParcels?.rows
              ?.map((row) =>
                row.purchases.map((purchase) => ({
                  ...purchase,
                  parcelId: row.id,
                  parcelTrackingCode: row.trackingCode,
                })),
              )
              .flat()
              .filter(
                (purchase) =>
                  purchase.product.name
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) &&
                  purchase.forwardingId === null,
              ),
          ]
        : [],
    [purchaseParcelsData, searchValue],
  );

  return (
    <Box>
      <Flex justifyContent={'flex-end'}>
        <Button
          intlId={buttonText}
          onPress={handlerButtonPress}
          width={'auto'}
          minWidth={'120px'}
          size='sm'
        />
      </Flex>
      <AddModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        searchValue={searchValue}
        onChangeSearchValue={changeSearchValue}
        queryProps={queryProps}
        title='app.addPurchase'
      >
        <RequestHandler {...queryProps}>
          {IS_DESKTOP_SCREEN ? (
            <DesktopTable editable={!isMultiSelect} inModal>
              <DesktopTable.Head>
                <DesktopTable.Title width={50} />
                <DesktopTable.Title intlId={'app.nameProduct'} textAlign={'left'} />
                <DesktopTable.Title width={10} />
                <DesktopTable.Title width={80} />
              </DesktopTable.Head>
              <DesktopTable.Body>
                {purchases.map((item, index) => (
                  <DesktopTable.Row key={index}>
                    <DesktopTable.Cell width={50}>
                      <ImageView
                        uri={
                          item?.product?.photos[0]?.formats?.find(
                            (el) => el.format === 'smallCrop',
                          ).pathWithTime
                        }
                      />
                    </DesktopTable.Cell>
                    <DesktopTable.Cell textAlign={'left'}>
                      <Box>
                        <Typography color='primary.default'>
                          {item?.product?.name}
                        </Typography>
                        <Typography fontSize='xs'>{item?.product?.sku}</Typography>
                        <Typography fontSize='xs'>
                          #{item.id}&nbsp;&nbsp;&nbsp;
                          {intl.formatMessage({ id: 'app.price' })}: {item.price},&nbsp;
                          {item.quantity} {intl.formatMessage({ id: 'app.pcs' })}&nbsp;
                          {intl.formatMessage({ id: 'app.date' })}:&nbsp;
                          {formatDateTime(+item.purchaseTime, 'dd.MM.yyyy')}.&nbsp; (
                          {item.parcelTrackingCode})
                        </Typography>
                      </Box>
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>
                      <Typography />
                    </DesktopTable.Cell>
                    <ModalButton
                      isAvailable
                      addedItemsIds={addedPurchasesIds}
                      handleSet={onAddPurchaseHandler}
                      item={item}
                    />
                  </DesktopTable.Row>
                ))}
              </DesktopTable.Body>
            </DesktopTable>
          ) : (
            <>
              {purchases.map((item, index) => (
                <PurchaseCard key={index} item={item} handleSet={onAddPurchaseHandler} />
              ))}
            </>
          )}
        </RequestHandler>
      </AddModal>
      {isError && <Typography mt={5} intlId={'app.chooseSource'} color={'error'} />}
    </Box>
  );
};

AddForwardingPurchaseModal.propTypes = {
  buttonText: PropTypes.string,
  onAddPurchase: PropTypes.func.isRequired,
  addedPurchases: PropTypes.array.isRequired,
  isMultiSelect: PropTypes.bool,
  currencyRatio: PropTypes.object,
  forwardingParcels: PropTypes.array.isRequired,
};
