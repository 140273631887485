import { useLazyQuery, useQuery } from '@apollo/client';

import { DATA_REQUEST_LIMIT, LIMIT_ORDERS, LIMIT_USERS } from '../constants';
import { useAuthContext, useSearchContext } from '../context';
import * as QUERIES from '../graphql/queries';
import { LocalStorage } from '../services';
import useFilter from './useFilter';
import useParams from './useParams';
import { useStockLocationsOptions } from './useStockLocationsOptions';

export const useLoginQuery = () => {
  const { onSuccessAuth } = useAuthContext();

  return useLazyQuery(QUERIES.LOGIN_QUERY, {
    onCompleted: (response) => {
      if (response?.loginUser?.token) {
        LocalStorage.setValue('token', response?.loginUser?.token);
        onSuccessAuth();
      }
    },
  });
};

export const useStockPlaceQuery = (placeId) => {
  const params = useParams();
  return useQuery(QUERIES.GET_STOCK_PLACE, {
    skip: !params?.id && !placeId,
    variables: {
      id: params?.id || placeId,
    },
  });
};

export const useStockPlacesQuery = () => {
  const { debouncedSearchValue } = useSearchContext();

  return useQuery(QUERIES.GET_STOCK_PLACES, {
    variables: {
      offset: 0,
      limit: LIMIT_ORDERS,
      search: debouncedSearchValue,
    },
  });
};

export const useFindStockPlacesQuery = () => {
  const { debouncedSearchValue } = useSearchContext();

  const { getShelvingOptions } = useStockLocationsOptions();

  const { filters } = useFilter();

  return useQuery(QUERIES.GET_STOCK_PLACES, {
    variables: {
      offset: 0,
      limit: DATA_REQUEST_LIMIT,
      search: debouncedSearchValue,
      locationIds: filters?.roomId
        ? filters?.shelvingId
          ? [+filters?.shelvingId]
          : getShelvingOptions(+filters?.roomId).map((shelving) => +shelving?.value)
        : undefined,
      sort: {
        type: filters?.direction || 'desc',
        field: filters?.field || 'id',
      },
    },
  });
};

export const useGetUserQuery = () => {
  return useQuery(QUERIES.GET_CURRENT_USER);
};

export const useStockLocationsQuery = () => {
  return useQuery(QUERIES.GET_STOCK_LOCATIONS);
};

export const useOrdersFilterQuery = () => {
  return useQuery(QUERIES.ORDERS_FILTER);
};

export const useCustomerQuery = () => {
  const params = useParams();
  const id = params?.id;

  return useQuery(id ? QUERIES.GET_CUSTOMER : QUERIES.GET_CUSTOMER_FIELDS, {
    variables: {
      ...(id ? { id } : undefined),
    },
  });
};

export const useGetCustomerLazyQuery = (id) => {
  return useLazyQuery(QUERIES.GET_CUSTOMER_OPTION, {
    variables: {
      id,
    },
  });
};

export const useGetRolesQuery = () => {
  return useQuery(QUERIES.GET_ROLES_QUERY);
};

export const useGetUserByIdQuery = () => {
  const params = useParams();
  const id = params?.id;

  return useQuery(QUERIES.GET_USER_QUERY, {
    skip: !id,
    variables: {
      ...({ id } || undefined),
    },
  });
};

export const useCustomersFilterQuery = () => {
  return useQuery(QUERIES.CUSTOMERS_FILTER);
};

export const useGetProductsQuery = (hasAccess, skip) => {
  const { debouncedSearchValue } = useSearchContext();

  return useQuery(QUERIES.GET_PRODUCTS, {
    variables: {
      search: debouncedSearchValue,
      limit: DATA_REQUEST_LIMIT,
      hasAccess,
    },
    skip,
  });
};

export const useGetPurchaseQuery = () => {
  const params = useParams();
  const id = params?.id;

  return useQuery(QUERIES.GET_PURCHASE, {
    skip: !params?.id,
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });
};

export const useAvailabilityQuery = (forwardingId) => {
  return useQuery(QUERIES.GET_AVAILABILITY, {
    variables: {
      filters: { forwardingId: forwardingId },
    },
  });
};

export const useGetCustomersQuery = () => {
  const { filters } = useFilter();

  const { debouncedSearchValue } = useSearchContext();

  return useQuery(QUERIES.GET_CUSTOMERS, {
    variables: {
      offset: 0,
      limit: DATA_REQUEST_LIMIT,
      search: debouncedSearchValue,
      order: filters?.direction || 'asc',
      orderBy: filters?.field || 'id',
      filters: {
        dateFrom: filters?.dateFrom || undefined,
        dateTo: filters?.dateTo || undefined,
        emailIds: filters?.emailIds || undefined,
        phoneIds: filters?.phoneIds || undefined,
      },
    },
  });
};

export const useOrdersQuery = () => {
  const { debouncedSearchValue } = useSearchContext();
  const { filters } = useFilter();

  let filtersWithValues = {};

  Object.entries(filters).forEach(([key, value]) => {
    if (value) return (filtersWithValues[key] = value);
  });

  const { field, direction, ...filtersWithoutSort } = filtersWithValues;

  return useQuery(QUERIES.GET_ORDERS, {
    variables: {
      offset: 0,
      limit: DATA_REQUEST_LIMIT,
      order: direction || 'desc',
      orderBy: field || 'datetime',
      filters: filtersWithoutSort,
      search: debouncedSearchValue,
    },
  });
};

export const useGetPurchasesQuery = () => {
  const { debouncedSearchValue } = useSearchContext();

  return useQuery(QUERIES.PURCHASES_QUERY, {
    variables: {
      offset: 0,
      limit: DATA_REQUEST_LIMIT,
      search: debouncedSearchValue,
    },
    fetchPolicy: 'network-only',
  });
};

export const useGetPurchasesInAcceptanceQuery = (forwardingId) => {
  const { debouncedSearchValue } = useSearchContext();

  return useQuery(QUERIES.PURCHASES_WITH_ACCEPTED_QUANTITY_QUERY, {
    variables: {
      offset: 0,
      search: debouncedSearchValue,
      forwardingId: forwardingId,
    },
    fetchPolicy: 'network-only',
  });
};

export const useGetUsersQuery = () => {
  const { debouncedSearchValue } = useSearchContext();

  const { filters } = useFilter();

  return useQuery(QUERIES.GET_USERS, {
    variables: {
      offset: 0,
      limit: LIMIT_USERS,
      order: filters?.direction || 'desc',
      orderBy: filters?.field || 'datetime',
      filters: {
        name: filters?.name || '',
        dateFrom: filters?.dateFrom || '',
        dateTo: filters?.dateTo || '',
        email: filters?.email || '',
        phone: filters?.phone || '',
        roles: filters?.role || [],
      },
      search: debouncedSearchValue,
    },
  });
};

export const useGetProductForOrder = (options) => {
  return useLazyQuery(QUERIES.GET_PRODUCT_QUERY, { ...options });
};

export const useGetProduct = () => {
  const params = useParams();

  return useQuery(QUERIES.GET_PRODUCT_QUERY, {
    skip: !params?.id,
    variables: {
      id: params?.id,
    },
  });
};

export const useGetProductLazy = (id) =>
  useLazyQuery(QUERIES.GET_PRODUCT_QUERY, {
    variables: {
      id,
    },
  });

export const useGetConditionsQuery = () => {
  return useQuery(QUERIES.GET_CONDITIONS_QUERY);
};

export const useGetSourcesQuery = () => {
  return useQuery(QUERIES.GET_SOURCES_QUERY);
};

export const useGetPurchaseParcelStatusesQuery = () => {
  return useQuery(QUERIES.GET_PURCHASE_PARCEL_STATUSES_QUERY);
};

export const useGetPurchaseForwardingStatusesQuery = () => {
  return useQuery(QUERIES.GET_PURCHASE_FORWARDING_STATUSES_QUERY);
};

export const useGetPurchaseSourcesQuery = () => {
  return useQuery(QUERIES.GET_PURCHASE_SOURCES_QUERY, {
    variables: {
      offset: 0,
      limit: DATA_REQUEST_LIMIT,
    },
  });
};

export const useGetPurchaseSourceQuery = (id) => {
  return useQuery(QUERIES.GET_PURCHASE_SOURCE_QUERY, {
    variables: {
      purchaseSourceId: id,
    },
    skip: !id,
  });
};

export const useGetPurchaseParcelQuery = (id) => {
  return useQuery(QUERIES.GET_PURCHASE_PARCEL_QUERY, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });
};

export const useHasScopesQuery = (scopes) => {
  return useQuery(QUERIES.HAS_SCOPES_QUERY, {
    variables: {
      scopes,
    },
    skip: !scopes,
  });
};

export const useGetPurchaseForwardingQuery = (id) => {
  return useQuery(QUERIES.GET_PURCHASE_FORWARDING_QUERY, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });
};

export const useGetPurchaseParcelsQuery = () => {
  return useQuery(QUERIES.GET_PURCHASE_PARCELS_QUERY, {
    variables: { offset: 0, limit: DATA_REQUEST_LIMIT },
    fetchPolicy: 'network-only',
  });
};

export const useGetPurchaseForwardingsQuery = () => {
  return useQuery(QUERIES.GET_PURCHASE_FORWARDINGS_QUERY, {
    variables: { offset: 0, limit: DATA_REQUEST_LIMIT },
    fetchPolicy: 'network-only',
  });
};

export const useGetCurrenciesQuery = () => {
  return useQuery(QUERIES.GET_CURRENCIES_QUERY, {
    fetchPolicy: 'network-only',
  });
};

export const useGetCurrencyQuery = () => {
  const params = useParams();

  return useQuery(QUERIES.GET_CURRENCY_QUERY, {
    skip: !params?.id,
    variables: {
      id: params?.id,
    },
  });
};

export const useGetCurrencyRatioQuery = (id) => {
  return useQuery(QUERIES.GET_CURRENCY_RATIO_QUERY, {
    variables: {
      id: id,
    },
    skip: !id,
  });
};

export const useGetExchangeQuery = () => {
  const params = useParams();

  return useQuery(QUERIES.GET_EXCHANGE_QUERY, {
    skip: !params?.id,
    variables: {
      id: params?.id,
    },
  });
};

export const useGetExchangesQuery = () => {
  const { debouncedSearchValue } = useSearchContext();
  const { filters } = useFilter();

  return useQuery(QUERIES.GET_EXCHANGES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      offset: 0,
      limit: LIMIT_ORDERS,
      order: filters?.direction || 'desc',
      orderBy: filters?.field || 'datetime',
      search: debouncedSearchValue,
      filters: {
        dateFrom: filters?.dateFrom || '',
        dateTo: filters?.dateTo || '',
        fromBankAccountId: filters?.fromBankAccountId
          ? Number(filters?.fromBankAccountId)
          : undefined,
        toBankAccountId: filters?.toBankAccountId
          ? Number(filters?.toBankAccountId)
          : undefined,
        fromCurrencyId: filters?.fromCurrencyId
          ? Number(filters?.fromCurrencyId)
          : undefined,
        toCurrencyId: filters?.toCurrencyId ? Number(filters?.toCurrencyId) : undefined,
      },
    },
  });
};

export const useGetReportsQuery = () => {
  const { filters } = useFilter();

  return useQuery(QUERIES.GET_REPORTS_QUERY, {
    variables: {
      filters: {
        dateFrom: filters?.dateFrom || '',
        dateTo: filters?.dateTo || '',
        typeIds: filters?.typeIds,
        includeExchanges: filters?.includeExchanges === 'true' || false,
      },
    },
  });
};

export const useGetTransactionsQuery = () => {
  const { filters } = useFilter();
  const { debouncedSearchValue } = useSearchContext();

  return useQuery(QUERIES.GET_TRANSACTIONS_QUERY, {
    variables: {
      offset: 0,
      limit: DATA_REQUEST_LIMIT,
      search: debouncedSearchValue,
      order: filters?.direction || 'desc',
      orderBy: filters?.field || 'datetime',
      filters: {
        dateFrom: filters?.dateFrom || '',
        dateTo: filters?.dateTo || '',
        typeIds: filters?.typeIds,
        ...(filters?.bankAccountId && { bankAccountId: filters?.bankAccountId }),
      },
    },
  });
};

export const useGetTransactionsTypesQuery = () => {
  return useQuery(QUERIES.GET_TRANSACTIONS_TYPES_QUERY);
};

export const useGetTransactionQuery = () => {
  const params = useParams();
  const id = params?.id;

  return useQuery(QUERIES.GET_TRANSACTION_QUERY, {
    skip: !id,
    variables: { id: +id },
  });
};

export const useFindProducts = (search, source) => {
  return useQuery(QUERIES.FIND_PRODUCTS, {
    variables: { search: search || '', source: source || 0 },
  });
};

export const useFindPurchaseForwardingParcels = (ids, source) => {
  return useQuery(QUERIES.GET_PURCHASE_FORWARDING_PARCELS_QUERY, {
    variables: { offset: 0, limit: DATA_REQUEST_LIMIT, ids, source },
  });
};

export const useGetPackagingTemplates = () => {
  const { debouncedSearchValue } = useSearchContext();

  return useQuery(QUERIES.GET_PACKAGING_TEMPLATES, {
    variables: {
      search: debouncedSearchValue,
    },
  });
};

export const useGetPackagingTemplate = () => {
  const params = useParams();
  return useQuery(QUERIES.GET_PACKAGING_TEMPLATE, {
    skip: !params?.id,
    variables: { id: +params.id },
  });
};

export const useOrderQuery = () => {
  const params = useParams();
  const id = params?.id;

  return useQuery(id ? QUERIES.GET_ORDER : QUERIES.GET_ORDER_FIELDS, {
    variables: {
      id: id ? id : undefined,
    },
  });
};

export const useOrderItemsQuery = (options) => {
  return useLazyQuery(QUERIES.GET_ORDER_ITEMS, { ...options });
};

export const useGetCityQuery = (id) => {
  return useQuery(QUERIES.GET_CITY, {
    variables: {
      id,
    },
    skip: !id,
  });
};

export const useFindCitiesQuery = (search) => {
  return useQuery(QUERIES.FIND_CITIES, {
    variables: {
      search: search || '',
    },
    // skip: !search?.length,
  });
};

export const useGetWarehousesNP = (options) => {
  return useLazyQuery(QUERIES.GET_WAREHOUSES_NP, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetWarehouseNP = (options) => {
  return useLazyQuery(QUERIES.GET_WAREHOUSES_NP, {
    ...options,
  });
};

export const useGetWarehousesUkrposhta = (options) => {
  return useLazyQuery(QUERIES.GET_WAREHOUSES_UKR_POSHTA, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetCitiesNovaPoshta = (options) => {
  return useLazyQuery(QUERIES.GET_CITIES_NP, {
    fetchPolicy: 'network-only',
    ...options,
  });
};
export const useGetCityNovaPoshta = (options) => {
  return useLazyQuery(QUERIES.GET_CITIES_NP, {
    ...options,
  });
};

export const useGetCitiesUkrposhta = (options) => {
  return useLazyQuery(QUERIES.GET_CITIES_UKR_POSHTA, {
    ...options,
  });
};

export const useGetStreetsNovaPoshta = (options) =>
  useLazyQuery(QUERIES.GET_STREETS_NP, {
    ...options,
  });

export const useGetPayersTypesNP = () => useQuery(QUERIES.GET_PAYERS_TYPES_NP);

export const useFindForwardingsQuery = () => {
  const { debouncedSearchValue } = useSearchContext();

  return useQuery(QUERIES.FIND_PURCHASE_FORWARDINGS_QUERY, {
    variables: {
      search: debouncedSearchValue,
      offset: 0,
      limit: 100,
    },
  });
};

export const usePhotoByIdQuery = (id) => {
  return useQuery(QUERIES.GET_PHOTO_BY_ID, {
    variables: {
      photoId: id,
    },
  });
};

export const useGetBankAccounts = () => {
  const { filters } = useFilter();

  return useQuery(QUERIES.GET_BANKS_ACCOUNTS, {
    fetchPolicy: 'network-only',
    variables: {
      sort: {
        type: filters?.direction || 'desc',
        field: filters?.field || 'id',
      },
      filters: {
        currencyId: filters?.currencyId || undefined,
        isArchived: filters?.isArchived === 'true',
      },
    },
  });
};

export const useGetBankAccountsForField = () => {
  return useQuery(QUERIES.GET_BANKS_ACCOUNTS, {
    fetchPolicy: 'network-only',
  });
};

export const useGetBankAccount = () => {
  const params = useParams();

  return useQuery(QUERIES.GET_BANK_ACCOUNT, {
    skip: !params?.id,
    variables: {
      id: params?.id,
    },
  });
};

export const useGetShippingTypesQuery = () => {
  return useQuery(QUERIES.GET_SHIPPING_TYPES);
};

export const useGetShippingTypeQuery = (id) => {
  return useQuery(QUERIES.GET_SHIPPING_TYPE, {
    variables: {
      id,
    },
    skip: !id,
  });
};

export const useGetPaymentTypesQuery = () => {
  return useQuery(QUERIES.GET_PAYMENT_TYPES);
};

export const useGetPaymentTypeQuery = (id) => {
  return useQuery(QUERIES.GET_PAYMENT_TYPE, {
    variables: {
      id,
    },
    skip: !id,
  });
};

export const useGetStatusesQuery = () => {
  return useQuery(QUERIES.GET_STATUSES);
};

export const useGetPromOrderStatusMapsQuery = () => {
  return useQuery(QUERIES.GET_PROM_ORDER_STATUS_MAPS);
};

export const useGetPromOrderStatusMapQuery = (options) => {
  return useQuery(QUERIES.GET_PROM_ORDER_STATUS_MAP, { ...options });
};

export const useGetFlexibleshopOrderStatusMapsQuery = () => {
  return useQuery(QUERIES.GET_FLEXIBLESHOP_ORDER_STATUS_MAPS);
};

export const useGetFlexibleshopOrderStatusMapQuery = (options) => {
  return useQuery(QUERIES.GET_FLEXIBLESHOP_ORDER_STATUS_MAP, { ...options });
};

export const useGetRozetkaOrderStatusMapsQuery = () => {
  return useQuery(QUERIES.GET_ROZETKA_ORDER_STATUS_MAPS);
};

export const useGetRozetkaOrderStatusMapQuery = (options) => {
  return useQuery(QUERIES.GET_ROZETKA_ORDER_STATUS_MAP, { ...options });
};

export const useGetStrapiOrderStatusMapsQuery = () => {
  return useQuery(QUERIES.GET_STRAPI_ORDER_STATUS_MAPS);
};

export const useGetStrapiOrderStatusMapQuery = (options) => {
  return useQuery(QUERIES.GET_STRAPI_ORDER_STATUS_MAP, { ...options });
};

export const useGetAlloOrderStatusMapsQuery = () => {
  return useQuery(QUERIES.GET_ALLO_ORDER_STATUS_MAPS);
};

export const useGetAlloOrderStatusMapQuery = (options) => {
  return useQuery(QUERIES.GET_ALLO_ORDER_STATUS_MAP, { ...options });
};

export const useGetPriceTags = (options) => {
  return useLazyQuery(QUERIES.GET_PRICE_TAGS, {
    ...options,
  });
};

export const useGetProductLocales = (options) => {
  return useLazyQuery(QUERIES.GET_PRODUCT_LOACALES, {
    ...options,
  });
};

export const useGetRozetkaProduct = () => {
  const params = useParams();
  return useQuery(QUERIES.GET_ROZETKA_PRODUCT, {
    skip: !params?.id,
    variables: {
      internalId: Number(params?.id),
    },
    fetchPolicy: 'network-only',
  });
};

export const useGetParamsFromRozetka = (options) => {
  return useLazyQuery(QUERIES.GET_PARAMS_FROM_ROZETKA, {
    ...options,
  });
};

export const useGetRozetkaCategories = (options) => {
  return useLazyQuery(QUERIES.GET_ROZETKA_CATEGORIES, {
    ...options,
  });
};

export const useGetRozetkaVendors = (options) => {
  return useLazyQuery(QUERIES.GET_ROZETKA_VENDORS, {
    ...options,
  });
};

export const useGetCustomParams = () => {
  const { debouncedSearchValue } = useSearchContext();

  return useQuery(QUERIES.GET_CUSTOM_PARAMS, {
    variables: {
      offset: 0,
      limit: DATA_REQUEST_LIMIT,
      search: debouncedSearchValue,
    },
    fetchPolicy: 'network-only',
  });
};

export const useGetCustomParamsLazy = (options) => {
  return useLazyQuery(QUERIES.GET_CUSTOM_PARAMS_WITH_OPTIONS, {
    ...options,
  });
};

export const useGetCustomParam = (options) => {
  const params = useParams();
  return useQuery(QUERIES.GET_CUSTOM_PARAM, {
    skip: !params?.id,
    variables: {
      customParamId: Number(params?.id),
    },
    ...options,
  });
};

export const useGetOrdersReceipts = (options) => {
  const params = useParams();
  return useQuery(QUERIES.GET_ORDER_RECEIPTS, {
    skip: !params?.id,
    variables: {
      orderId: Number(params?.id),
    },
    ...options,
  });
};

export const useGetAlloProduct = () => {
  const params = useParams();
  return useQuery(QUERIES.GET_ALLO_PRODUCT, {
    skip: !params?.id,
    variables: {
      internalId: Number(params?.id),
    },
    fetchPolicy: 'network-only',
  });
};

export const useGetAlloCategories = (options) => {
  return useLazyQuery(QUERIES.GET_ALLO_CATEGORIES, {
    ...options,
  });
};

export const useGetAlloVendors = (options) => {
  return useLazyQuery(QUERIES.GET_ALLO_VENDORS, {
    ...options,
  });
};

export const useOrderCancelReasons = (options) => {
  return useLazyQuery(QUERIES.GET_ORDER_CANCEL_REASONS, {
    ...options,
  });
};

export const useGetEpicenterOrderStatusMapsQuery = () => {
  return useQuery(QUERIES.GET_EPICENTER_ORDER_STATUS_MAPS);
};

export const useGetEpicenterOrderStatusMapQuery = (options) => {
  return useQuery(QUERIES.GET_EPICENTER_ORDER_STATUS_MAP, { ...options });
};

export const useOrderStatisticQuery = () => {
  const { debouncedSearchValue } = useSearchContext();
  const { filters } = useFilter();

  // eslint-disable-next-line no-unused-vars
  const { field, direction, ...filtersWithoutSort } = filters;

  return useQuery(QUERIES.GET_ORDER_STATISTIC, {
    variables: {
      filters: filtersWithoutSort,
      search: debouncedSearchValue,
    },
    skip: !filters.dateFrom && !filters.dateTo,
  });
};
