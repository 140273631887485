import { MaterialIcons } from '@expo/vector-icons';
import { Box, Flex, Icon, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native';

import { TextField, TextLink } from '../../../../components';
import { useIntl } from '../../../../hooks';

const ParcelStructureMobileItemField = ({
  intlId = '',
  name = '',
  placeholderIntlId,
  text = '',
  type = 'text',
  numberFixedCount,
}) => {
  const intl = useIntl();

  return (
    <Flex align={'center'} justify={'space-between'}>
      <Text color={'gray.main'} fontSize={'14px'} width={'85px'} mr={'30px'}>
        {intl.formatMessage({ id: intlId })}
      </Text>
      {text ? (
        <Text>{text}</Text>
      ) : (
        <TextField
          inputProps={{
            textAlign: 'right',
          }}
          flexGrow={1}
          type={type}
          numberFixedCount={numberFixedCount}
          name={name}
          placeholderIntlId={placeholderIntlId}
        />
      )}
    </Flex>
  );
};

ParcelStructureMobileItemField.propTypes = {
  intlId: PropTypes.string,
  name: PropTypes.string,
  placeholderIntlId: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number']),
  numberFixedCount: PropTypes.number,
};

const ParcelStructureMobileItem = ({
  index,
  item,
  handleRemove,
}) => {
  return (
    <Box mt={'lg'}>
      {item?.name ? (
        <TextLink link={`/warehouse/product/${item?.id}`}>{item?.name}</TextLink>
      ) : (
        <Flex>
          <TextLink link={`/purchase/purchase'/${item?.id}`}>
            {item?.product?.name}
          </TextLink>
          <Text color='black'>{` (${item?.id})`}</Text>
        </Flex>
      )}
      <ParcelStructureMobileItemField
        intlId={'app.costUSD'}
        type={'number'}
        name={`purchases[${index}].price`}
        placeholderIntlId={'app.price'}
      />
      <ParcelStructureMobileItemField
        intlId={'app.quantity'}
        type={'number'}
        numberFixedCount={0}
        name={`purchases[${index}].quantity`}
        placeholderIntlId={'app.quantity'}
      />
      <ParcelStructureMobileItemField
        intlId={'app.sum'}
        text={`${(item?.price * item?.quantity).toFixed(2)} $`}
      />
      <Box
        pb={'lg'}
        borderBottomWidth={'1px'}
        borderBottomColor={'gray.default'}
        mt={'34px'}
      >
        <TouchableOpacity onPress={handleRemove}>
          <Flex align={'center'} justify={'center'}>
            <Icon
              size={'sm'}
              color={'gray.dark'}
              as={<MaterialIcons name={'delete'} />}
            />
          </Flex>
        </TouchableOpacity>
      </Box>
    </Box>
  );
};

ParcelStructureMobileItem.propTypes = {
  index: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default ParcelStructureMobileItem;
