import { Box, Flex } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { IS_DESKTOP_SCREEN } from '../../constants';
import { useNotificationsContext, useSearchContext } from '../../context';
import { useGetPurchasesInAcceptanceQuery } from '../../hooks';
import { formatDateTime } from '../../utils';
import {
  AddModal,
  Button,
  DesktopTable,
  ImageView,
  ModalButton,
  PurchaseCard,
  RequestHandler,
  Typography,
} from '../index';

export const AddAcceptanceModal = ({
  onAddPurchase,
  addedPurchase = [],
  forwardingId,
}) => {
  const intl = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { searchValue, changeSearchValue } = useSearchContext();

  const { showNotification } = useNotificationsContext();

  const { data, ...queryProps } = useGetPurchasesInAcceptanceQuery(forwardingId);

  const purchases = data?.purchases?.filter(
    (purchase) => purchase.quantity - purchase.acceptedQuantity > 0,
  );

  const addedPurchasesIds = addedPurchase?.map((el) => el?.id);

  const onAddPurchaseHandler = (purchase) => {
    onAddPurchase(purchase);
  };

  const handlerButtonPress = () => {
    if (!forwardingId) {
      showNotification({
        message: 'app.chooseTrackingCode',
        type: 'error',
        title: 'snackbar.error',
      });
    } else {
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    changeSearchValue('');
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Flex justifyContent={'flex-end'}>
        <Button
          intlId={'app.addProduct'}
          onPress={handlerButtonPress}
          minWidth={'120px'}
          size='sm'
        />
      </Flex>
      <AddModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        searchValue={searchValue}
        onChangeSearchValue={changeSearchValue}
        queryProps={queryProps}
        title='app.addPurchase'
      >
        <RequestHandler {...queryProps}>
          {IS_DESKTOP_SCREEN ? (
            <DesktopTable editable={false} inModal>
              <DesktopTable.Head>
                <DesktopTable.Title width={50} />
                <DesktopTable.Title intlId={'app.title'} textAlign={'left'} />
                <DesktopTable.Title width={10} />
                <DesktopTable.Title width={80} />
              </DesktopTable.Head>
              <DesktopTable.Body>
                {purchases?.map((purchase, index) => (
                  <DesktopTable.Row key={index}>
                    <DesktopTable.Cell width={50}>
                      <ImageView uri={purchase.product?.photo?.path} />
                    </DesktopTable.Cell>
                    <DesktopTable.Cell textAlign={'left'}>
                      <Box>
                        <Typography color='primary.default'>
                          {purchase.product?.name}
                        </Typography>
                        <Typography fontSize='xs'>{purchase.product?.sku}</Typography>
                        <Typography fontSize='xs'>
                          #{purchase.id}&nbsp;&nbsp;&nbsp;
                          {intl.formatMessage({ id: 'app.price' })}: {purchase.price}
                          ,&nbsp;
                          {purchase.quantity} {intl.formatMessage({ id: 'app.pcs' })}
                          &nbsp;
                          {intl.formatMessage({ id: 'app.date' })}:&nbsp;
                          {formatDateTime(+purchase.purchaseTime, 'dd.MM.yyyy')}.&nbsp;
                        </Typography>
                      </Box>
                    </DesktopTable.Cell>
                    <DesktopTable.Cell>
                      <Typography />
                    </DesktopTable.Cell>
                    <ModalButton
                      disableText={'app.alreadyInStock'}
                      addedItemsIds={addedPurchasesIds}
                      handleSet={onAddPurchaseHandler}
                      item={purchase}
                    />
                  </DesktopTable.Row>
                ))}
              </DesktopTable.Body>
            </DesktopTable>
          ) : (
            <>
              {purchases?.map((purchase, index) => (
                <PurchaseCard
                  key={index}
                  item={purchase}
                  handleSet={onAddPurchaseHandler}
                />
              ))}
            </>
          )}
        </RequestHandler>
      </AddModal>
    </Box>
  );
};

AddAcceptanceModal.propTypes = {
  onAddPurchase: PropTypes.func.isRequired,
  addedPurchase: PropTypes.array.isRequired,
  forwardingId: PropTypes.number,
};
