import { useState } from 'react';

import { useNotificationsContext } from '../context';
import { calculateCostDelivery } from '../utils';
import useHistory from './useHistory';
import {
  usePurchaseForwardingMutation,
  useSetForwardingToPurchasesMutation,
} from './useMutations';

export const useSaveForwarding = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  const [forwardingMutation] = usePurchaseForwardingMutation();

  const [setForwardingToPurchases] = useSetForwardingToPurchasesMutation();

  const [isSaveForwardingLoading, setIsSaveForwardingLoading] = useState(false);

  const onSaveForwarding = async (values, { setFieldValue }) => {
    setIsSaveForwardingLoading(true);

    const refreshedPurchases = calculateCostDelivery(
      values?.purchases,
      values?.deliveryPrice,
      setFieldValue,
    );

    const forwardingMutationVariables = {
      parcelIds: values?.parcels?.filter((el) => el?.id).map((el) => +el.id),
      deliveryPrice: values?.deliveryPrice,
      date: values?.date ? new Date(values.date) : undefined,
      trackingCode: values.trackingCode,
      status: Number(values.status),
    };

    if (values.id) {
      forwardingMutationVariables.id = values.id;
    }

    const forwardingMutationResponse = await forwardingMutation({
      variables: { input: forwardingMutationVariables },
    });

    const purchaseForwarding = forwardingMutationResponse?.data?.purchaseForwarding;

    if (purchaseForwarding) {
      const purchases = refreshedPurchases.map((item) => ({
        id: +item?.id,
        deliveryPrice: +item?.deliveryPrice,
      }));

      if (purchases.length) {
        await setForwardingToPurchases({
          variables: {
            forwardingId: +purchaseForwarding?.id,
            input: { purchases },
          },
        });
      }

      setIsSaveForwardingLoading(false);
      showNotification({ message: 'forwarding.saved' });
      navigation.push('/purchase/forwardings');
    }
  };
  return [onSaveForwarding, isSaveForwardingLoading];
};
