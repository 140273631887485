export const calculateCostDelivery = (purchases, deliveryPrice, setFieldValue) => {
  const productsWeight = purchases?.reduce(
    (weight, purchase) => +weight + +purchase?.quantity * purchase?.product?.weight,
    0,
  );

  const weightPrice = +deliveryPrice / +productsWeight;
  const refreshedPurchases = [];

  purchases?.forEach((purchase) => {
    refreshedPurchases.push({
      ...purchase,
      deliveryPrice: +(+purchase?.product?.weight * +weightPrice)?.toFixed(2),
    });
  });
  setFieldValue('purchases', refreshedPurchases);
  return refreshedPurchases;
};
