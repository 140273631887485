import { MaterialIcons } from '@expo/vector-icons';
import { FieldArray, useFormikContext } from 'formik';
import { Flex, Icon, Text } from 'native-base';
import React from 'react';
import { TouchableOpacity } from 'react-native';

import {
  AddProductModal,
  BoxWrapper,
  DesktopTable,
  TextField,
  TextLink,
  Typography,
} from '../../../../components';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import { useDeletePurchase, useIntl } from '../../../../hooks';
import ParcelStructureMobileItem from './ParcelStructureMobileItem';

const ParcelStructure = () => {
  const { values } = useFormikContext();

  const intl = useIntl();

  const [deletePurchase] = useDeletePurchase();

  const purchasesQuantity = values.purchases.reduce(
    (accumulator, { quantity }) => accumulator + Number(quantity),
    0,
  );
  const purchasesTotalPrice = values.purchases.reduce(
    (accumulator, { price, quantity }) => accumulator + Number(price) * Number(quantity),
    0,
  );

  const handleAddProduct = (product, addField) => {
    addField({
      ...product,
      quantity: null,
      price: null,
      isNew: true,
    });
  };

  const handleRemove = (arrayHelpers, index, item) => {
    arrayHelpers.remove(index);

    !item?.isNew &&
      deletePurchase({
        variables: {
          id: +item.id,
        },
      });
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <BoxWrapper>
        <Flex direction={'row'} justify={'space-between'} align={'center'}>
          <Typography mb={0} intlId='app.structure' variant='heading' />
          <FieldArray
            name={'purchases'}
            render={(arrayHelpers) => (
              <AddProductModal
                buttonText={'app.addPurchase'}
                addedProducts={values?.purchases?.map(
                  (purchase) => purchase?.product || purchase,
                )}
                onAddProduct={(product) =>
                  handleAddProduct(product, arrayHelpers.push)
                }
                isMultiSelect
              />
            )}
          />
        </Flex>

        <DesktopTable editable={false}>
          <DesktopTable.Head>
            <DesktopTable.Title intlId='app.purchase' />
            <DesktopTable.Title intlId='app.costUSD' />
            <DesktopTable.Title intlId='app.quantity' />
            <DesktopTable.Title intlId='app.sum' />
          </DesktopTable.Head>
          <DesktopTable.Body>
            <FieldArray
              name={'purchases'}
              render={(arrayHelpers) => (
                <>
                  {values?.purchases.map((item, index) => {
                    return (
                      <DesktopTable.Row key={item?.id}>
                        <DesktopTable.Cell width={'40%'} maxWidth={'40%'}>
                          {item?.name ? (
                            <TextLink link={`/warehouse/product/${item?.id}`}>
                              {item?.name}
                            </TextLink>
                          ) : (
                            <Flex>
                              <TextLink link={`/purchase/purchase/${item?.id}`}>
                                {item?.product?.name}
                              </TextLink>
                              <Text color='black'>{` (${item?.id})`}</Text>
                            </Flex>
                          )}
                        </DesktopTable.Cell>
                        <DesktopTable.Cell>
                          <TextField
                            inputProps={{
                              textAlign: 'right',
                            }}
                            width={'90px'}
                            mb={0}
                            type={'number'}
                            name={`purchases[${index}].price`}
                            placeholderIntlId={'app.price'}
                          />
                        </DesktopTable.Cell>
                        <DesktopTable.Cell>
                          <TextField
                            inputProps={{
                              textAlign: 'center',
                            }}
                            width={'48px'}
                            mb={0}
                            type={'number'}
                            numberFixedCount={0}
                            name={`purchases[${index}].quantity`}
                            placeholderIntlId={'app.quantity'}
                          />
                        </DesktopTable.Cell>
                        <DesktopTable.Cell>
                          <Flex
                            direction={'row'}
                            align={'center'}
                            justify={'space-between'}
                          >
                            <Flex justify={'center'}>
                              <Text>{(item?.price * item?.quantity).toFixed(2)} $</Text>
                            </Flex>
                            <TouchableOpacity
                              onPress={() => handleRemove(arrayHelpers, index, item)}
                            >
                              <Icon
                                size={'sm'}
                                color={'gray.dark'}
                                as={<MaterialIcons name={'delete'} />}
                              />
                            </TouchableOpacity>
                          </Flex>
                        </DesktopTable.Cell>
                      </DesktopTable.Row>
                    );
                  })}
                </>
              )}
            />
            <DesktopTable.Row>
              <DesktopTable.Cell>
                <></>
              </DesktopTable.Cell>
              <DesktopTable.Cell>
                <></>
              </DesktopTable.Cell>
              <DesktopTable.Cell>
                <Flex justify={'flex-start'}>
                  <Text>
                    {`${purchasesQuantity} ${intl.formatMessage({ id: 'app.pcs' })}`}
                  </Text>
                </Flex>
              </DesktopTable.Cell>
              <DesktopTable.Cell>
                <Flex justify={'flex-start'}>
                  <Text>{purchasesTotalPrice.toFixed(2)} $</Text>
                </Flex>
              </DesktopTable.Cell>
            </DesktopTable.Row>
          </DesktopTable.Body>
        </DesktopTable>
      </BoxWrapper>
    );
  }

  return (
    <BoxWrapper>
      <FieldArray
        name={'purchases'}
        render={(arrayHelpers) => {
          return (
            <>
              <Flex direction={'row'} justify={'space-between'} align={'center'}>
                <Typography mb={0} intlId='app.structure' variant='heading' />
                <AddProductModal
                  buttonText={'app.addPurchase'}
                  addedProducts={values?.purchases?.map(
                    (purchase) => purchase?.product || purchase,
                  )}
                  onAddProduct={(product) =>
                    handleAddProduct(product, arrayHelpers.push)
                  }
                  isMultiSelect
                />
              </Flex>
              {values?.purchases.map((item, index) => (
                <ParcelStructureMobileItem
                  key={item?.id}
                  item={item}
                  handleRemove={() => handleRemove(arrayHelpers, index, item)}
                  index={index}
                />
              ))}
            </>
          );
        }}
      />
      <Flex mt={'lg'} justify={'space-between'} align={'center'}>
        <Text>{intl.formatMessage({ id: 'app.all' })}</Text>
        <Flex align={'center'}>
          <Text>{`${purchasesQuantity} ${intl.formatMessage({ id: 'app.pcs' })}`}</Text>
          <Text ml={'lg'}>{purchasesTotalPrice} $</Text>
        </Flex>
      </Flex>
    </BoxWrapper>
  );
};

export default ParcelStructure;
