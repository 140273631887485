import { MaterialIcons } from '@expo/vector-icons';
import { FieldArray, useFormikContext } from 'formik';
import { Flex, Icon, Text } from 'native-base';
import React from 'react';
import { useIntl } from 'react-intl';
import { TouchableOpacity } from 'react-native';

import {
  AddAcceptanceModal,
  BoxWrapper,
  DesktopTable,
  RequestHandler,
  Select,
  TextField,
  Typography,
} from '../../../../components';
import { CopyIcon } from '../../../../components/Icons';
import Input from '../../../../components/UI/Input';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import { useGetConditionsQuery } from '../../../../hooks';
import {
  getFormattedDate,
  getProductsTotalQuantity,
  transformSelectOptions,
} from '../../../../utils';

export const AcceptanceStructure = () => {
  const intl = useIntl();

  const { data, ...queryProps } = useGetConditionsQuery();
  const { values, setFieldValue } = useFormikContext();

  const purchases = values?.purchases;

  const handleAddPurchase = (purchase, addField) => {
    addField({
      ...purchase,
      availableQuantity: purchase.quantity - purchase.acceptedQuantity,
      isDuplicate: false,
      conditionId: '',
      quantity: '',
    });
  };

  const handleDuplicatePurchase = (purchase, addField, index) => {
    addField(index, {
      ...purchase,
      isDuplicate: true,
      conditionId: '',
      quantity: '',
    });
  };

  const handleRemovePurchase = (isDuplicate, removeField, index) => {
    !isDuplicate &&
      purchases[index + 1] &&
      setFieldValue(`purchases.${index + 1}.isDuplicate`, false);
    removeField(index);
  };

  const getMaxPurchaseQuantity = (purchaseId, index) => {
    const currentItemQuantity = values?.purchases[index]?.quantity || 0;
    const sumEntered =
      values?.purchases
        ?.filter((purchase) => purchase?.id === purchaseId)
        ?.reduce((sum, { quantity }) => sum + +quantity, 0) - currentItemQuantity;
    const availableQuantity =
      values?.purchases?.find(
        (purchase) => purchase?.id === purchaseId && purchase.isDuplicate === false,
      )?.availableQuantity || 0;

    return availableQuantity - sumEntered;
  };

  const canDuplicate = (purchaseId, isDuplicate) => {
    const sumEntered = values?.purchases
      ?.filter((purchase) => purchase?.id === purchaseId)
      ?.reduce((sum, { quantity }) => sum + +quantity, 0);
    const availableQuantity =
      values?.purchases?.find(
        (purchase) => purchase?.id === purchaseId && purchase.isDuplicate === false,
      )?.availableQuantity || 0;
    const res = availableQuantity - sumEntered;
    return res > 0 && !isDuplicate;
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <RequestHandler {...queryProps}>
        <BoxWrapper>
          <FieldArray
            name='purchases'
            render={(arrayHelpers) => {
              return (
                <>
                  <Flex justify='space-between' mb='xs'>
                    <Typography intlId='app.structure' variant='heading' mb={0} />
                    <AddAcceptanceModal
                      addedPurchase={purchases}
                      onAddPurchase={(purchase) =>
                        handleAddPurchase(purchase, arrayHelpers.push)
                      }
                      forwardingId={+values?.forwardingId}
                    />
                  </Flex>
                  <DesktopTable editable={false}>
                    <DesktopTable.Head>
                      {COLUMNS.map((column, index) => (
                        <DesktopTable.Title
                          key={index}
                          intlId={column.name}
                          width={column?.width || 'auto'}
                        />
                      ))}
                      <DesktopTable.Title />
                    </DesktopTable.Head>
                    <DesktopTable.Body>
                      {purchases?.map((item, index) => (
                        <DesktopTable.Row key={index}>
                          <DesktopTable.Cell color='primary.default' width={'293px'}>
                            {item?.product?.name}
                          </DesktopTable.Cell>
                          <DesktopTable.Cell width={'160px'}>
                            <Text>
                              {`№ ${item.id}`}
                              {item?.purchaseTime &&
                                ` ${intl.formatMessage({
                                  id: 'app.from',
                                })} ${getFormattedDate(item?.purchaseTime)}`}
                            </Text>
                            <Input
                              hidden={true}
                              name={`purchases.${index}.id`}
                              value={item?.id}
                            />
                          </DesktopTable.Cell>
                          <DesktopTable.Cell width={'160px'}>
                            <Select
                              name={`purchases.${index}.conditionId`}
                              options={transformSelectOptions({
                                data: data?.conditions,
                              })}
                              pr={50}
                              mb={0}
                            />
                          </DesktopTable.Cell>
                          <DesktopTable.Cell width={'70px'} maxWidth={'70px'}>
                            <Flex>
                              <TextField
                                width={'100px'}
                                mb={0}
                                mr={3}
                                name={`purchases.${index}.quantity`}
                                type={'number'}
                                numberFixedCount={0}
                                maxValue={getMaxPurchaseQuantity(item?.id, index)}
                              />
                              {!item?.isDuplicate && (
                                <Text>{`/ ${item?.availableQuantity}`}</Text>
                              )}
                            </Flex>
                          </DesktopTable.Cell>
                          <DesktopTable.Cell width={'30px'}>
                            <Flex justifyContent={'space-evenly'}>
                              {canDuplicate(item?.id, item?.isDuplicate) && (
                                <Flex
                                  cursor={'pointer'}
                                  onClick={() =>
                                    handleDuplicatePurchase(
                                      item,
                                      arrayHelpers.insert,
                                      index + 1,
                                    )
                                  }
                                >
                                  <CopyIcon />
                                </Flex>
                              )}
                              <Icon
                                as={<MaterialIcons name='delete' />}
                                size='18px'
                                color='gray.dark'
                                onPress={() => {
                                  handleRemovePurchase(
                                    item?.isDuplicate,
                                    arrayHelpers.remove,
                                    index,
                                  );
                                }}
                              />
                            </Flex>
                          </DesktopTable.Cell>
                        </DesktopTable.Row>
                      ))}
                      <DesktopTable.Row>
                        <DesktopTable.Cell>
                          <></>
                        </DesktopTable.Cell>
                        <DesktopTable.Cell>
                          <></>
                        </DesktopTable.Cell>
                        <DesktopTable.Cell>
                          <></>
                        </DesktopTable.Cell>
                        <DesktopTable.Cell>
                          <Flex justify='flex-start' mt='lg'>
                            {purchases && (
                              <Typography>
                                {`${getProductsTotalQuantity(
                                  purchases,
                                )} ${intl.formatMessage({ id: 'app.pcs' })}`}
                              </Typography>
                            )}
                          </Flex>
                        </DesktopTable.Cell>
                        <DesktopTable.Cell>
                          <></>
                        </DesktopTable.Cell>
                      </DesktopTable.Row>
                    </DesktopTable.Body>
                  </DesktopTable>
                </>
              );
            }}
          />
        </BoxWrapper>
      </RequestHandler>
    );
  }

  return (
    <RequestHandler {...queryProps}>
      <FieldArray
        name='purchases'
        render={(arrayHelpers) => {
          return (
            <>
              <Flex justifyContent='space-between' mb='lg'>
                <Typography intlId='app.structure' variant='heading' mb={0} />
                <AddAcceptanceModal
                  addedPurchase={purchases}
                  onAddPurchase={(purchase) =>
                    handleAddPurchase(purchase, arrayHelpers.push)
                  }
                  forwardingId={+values?.forwardingId}
                />
              </Flex>
              {purchases?.map((item, index) => (
                <BoxWrapper key={index}>
                  <Typography color='primary.default' mb='lg'>
                    {item?.product?.name}
                  </Typography>
                  <Typography color='primary.default' mb='lg'>
                    {`№ ${item.id}`}
                    {item?.purchaseTime &&
                      ` ${intl.formatMessage({ id: 'app.from' })} ${getFormattedDate(
                        item?.purchaseTime,
                      )}`}
                  </Typography>
                  <Input hidden={true} name={`purchases.${index}.id`} value={item?.id} />
                  <Select
                    label='app.condition'
                    name={`purchases.${index}.conditionId`}
                    options={transformSelectOptions({
                      data: data?.conditions,
                    })}
                  />
                  <Flex>
                    <TextField
                      flexGrow={2}
                      label='app.quantity'
                      type={'number'}
                      numberFixedCount={0}
                      maxValue={getMaxPurchaseQuantity(item?.id, index)}
                      name={`purchases.${index}.quantity`}
                    />
                    {!item?.isDuplicate && <Text> {`/ ${item?.availableQuantity}`}</Text>}
                  </Flex>
                  <Flex justifyContent={'space-evenly'}>
                    {canDuplicate(item?.id, item?.isDuplicate) && (
                      <Flex>
                        <TouchableOpacity
                          onPress={() =>
                            handleDuplicatePurchase(item, arrayHelpers.insert, index + 1)
                          }
                        >
                          <CopyIcon />
                        </TouchableOpacity>
                      </Flex>
                    )}
                    <TouchableOpacity
                      onPress={() => {
                        handleRemovePurchase(
                          item?.isDuplicate,
                          arrayHelpers.remove,
                          index,
                        );
                      }}
                    >
                      <Icon
                        as={<MaterialIcons name='delete' />}
                        size='18px'
                        color='gray.dark'
                      />
                    </TouchableOpacity>
                  </Flex>
                </BoxWrapper>
              ))}
            </>
          );
        }}
      />
    </RequestHandler>
  );
};

const COLUMNS = [
  { name: 'app.title' },
  { name: 'app.purchase', width: '160px' },
  { name: 'app.condition' },
  { name: 'app.quantity' },
];
