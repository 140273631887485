import { MaterialIcons } from '@expo/vector-icons';
import { Box, Flex, Icon, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native';

import { TextField } from '../../../../components';
import { useIntl } from '../../../../hooks';

const ForwardingStructureMobileItemField = ({
  intlId = '',
  name = '',
  placeholderIntlId,
  text = '',
  isDisabled,
  type = 'text',
  numberFixedCount,
}) => {
  const intl = useIntl();

  return (
    <Flex align={'center'} justify={'space-between'}>
      <Text color={'gray.main'} fontSize={'14px'} width={'85px'} mr={'30px'}>
        {intl.formatMessage({ id: intlId })}
      </Text>
      {text ? (
        <Text>{text}</Text>
      ) : (
        <TextField
          inputProps={{
            textAlign: 'right',
          }}
          flexGrow={1}
          type={type}
          isDisabled={isDisabled}
          numberFixedCount={numberFixedCount}
          name={name}
          placeholderIntlId={placeholderIntlId}
        />
      )}
    </Flex>
  );
};

ForwardingStructureMobileItemField.propTypes = {
  intlId: PropTypes.string,
  name: PropTypes.string,
  placeholderIntlId: PropTypes.string,
  text: PropTypes.string,
  isDisabled: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'number']),
  numberFixedCount: PropTypes.number,
};

const ForwardingStructureMobileItem = ({ index, item, handleRemove }) => {
  const intl = useIntl();

  return (
    <Box mt={'lg'}>
      <>
        <Text color={'primary.default'}>{item?.product?.name}</Text>
        <Text>{` (${item?.id})`}</Text>
      </>
      <Flex align={'center'} justify={'space-between'}>
        <Text width={'85px'} mr={'30px'}>
          {intl.formatMessage({ id: 'app.parcel' })}
        </Text>
        <Text>{item?.parcel?.trackingCode}</Text>
      </Flex>
      <ForwardingStructureMobileItemField
        intlId={'app.costUSD'}
        isDisabled
        name={`purchases[${index}].price`}
        type={'number'}
        placeholderIntlId={'app.price'}
      />
      <ForwardingStructureMobileItemField
        intlId={'app.priceDeliveryUSD'}
        isDisabled
        name={`purchases[${index}].deliveryPrice`}
        placeholderIntlId={'app.price'}
      />
      <ForwardingStructureMobileItemField
        intlId={'app.quantity'}
        name={`purchases[${index}].quantity`}
        placeholderIntlId={'app.quantity'}
        type={'number'}
        numberFixedCount={0}
      />
      <ForwardingStructureMobileItemField
        intlId={'app.sum'}
        text={`${((+item?.price + (+item?.deliveryPrice || 0)) * item?.quantity).toFixed(
          2,
        )} $`}
      />
      <Box
        pb={'lg'}
        borderBottomWidth={'1px'}
        borderBottomColor={'gray.default'}
        mt={'34px'}
      >
        <TouchableOpacity onPress={handleRemove}>
          <Flex align={'center'} justify={'center'}>
            <Icon
              size={'sm'}
              color={'gray.dark'}
              as={<MaterialIcons name={'delete'} />}
            />
          </Flex>
        </TouchableOpacity>
      </Box>
    </Box>
  );
};

ForwardingStructureMobileItem.propTypes = {
  index: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default ForwardingStructureMobileItem;
